<template>
<!-- 收款登记页 -->
<div class="table-responsive mt-5">
    <router-link :to="{ name: 'incomerecord_list' }">
        <el-button size="mini">返回收款列表</el-button>
    </router-link>
    <div class="text-center">
        <h3>收款登记</h3>
    </div>
    <table class="table table-bordered table-sm dark">
        <tbody>
            <tr>
                <td colspan="8" style="background-color: rgba(152, 150, 245, 0.05)">
                    基本信息
                </td>
            </tr>
            <tr>
                <td class="text-center align-middle"><small>日期</small></td>
                <td>
                    <el-date-picker v-model="formdata.in_date" type="date" placeholder="" size="small" format="YYYY/MM/DD" value-format="YYYY-MM-DD"></el-date-picker>
                </td>
                <td class="text-center align-middle"><small>支付单位</small></td>
                <td>
                    <el-input v-model="formdata.fk_company" placeholder="" size="small" />
                </td>
                <td class="text-center align-middle"><small>所属合同</small></td>
                <td>
                    <el-select v-model="formdata.in_contract_id" placeholder="" size="small" @change="contract_select">
                        <el-option v-for="item in contract_list" :key="item.id" :label="item.contract_name" :value="item.id">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td class="text-center align-middle"><small>凭证编号</small></td>
                <td>
                    <el-input v-model="formdata.text_no" placeholder="" size="small" />
                </td>
                <td class="text-center align-middle"><small>到账金额</small></td>
                <td>
                    <el-input v-model="formdata.in_amount" placeholder="" size="small" />
                </td>
                <td class="text-center align-middle"><small>收款账户</small></td>
                <td>
                    <!--banksclass 1-表示收付款账户  2表示往来款账户 -->
                    <sfbanks-select @select-change="sfbanksselect" :placeholder="sfbankplace" banksclass="1"></sfbanks-select>
                </td>
            </tr>
            <tr>
                <td>收款说明</td>
                <td colspan="7">
                    <el-input v-model="formdata.des_text" :rows="4" type="textarea" placeholder="" />
                </td>
            </tr>
        </tbody>
    </table>

    <!-- 确认新增付款申弹框 -->
    <el-popconfirm title="您确定要新增付款申吗？" confirm-button-text="确定" cancel-button-text="取消" confirm-button-type="primary" @confirm="addcontract">
        <template #reference>
            <el-button :disabled="issaveok" type="primary" size="mini" style="margin-left: 48%">保存新增</el-button>
        </template>
    </el-popconfirm>

    <div>
        <el-button :disabled="!issaveok" size="mini" @click="dialogVisible = true">批量上传</el-button>

        <el-dialog v-model="dialogVisible" width="50%" @closed="getFileList">
            <div class="text-center">
                <file-upload :fileclass="fileclass" :prdclassid="prdclassid" urlpath="admin/AccessoryFile" filedir="incomerecord"></file-upload>
            </div>
        </el-dialog>
        <el-table :data="tableData.data">
            <el-table-column prop="file_name" label="文件名称" width="300" />
            <el-table-column prop="create_time" label="上传时间" width="150" />
            <el-table-column label="操作" width="150" align="center">
                <template #default="scope">
                    <el-button-group>
                        <el-button size="small" plain @click="openPdfFile(scope.row.url_path)">查看</el-button>
                        <el-button size="small" plain type="danger" @click="deletePdfFile(scope.row.id)">删除</el-button>
                    </el-button-group>
                </template>
            </el-table-column>
        </el-table>
    </div>
</div>

<!-- 查看PDF文件 -->
<el-dialog v-model="pdfurlVisible" width="100%" fullscreen @closed="pdfurl = ''">
    <div class="text-center" style="height: 2000px">
        <iframe :src="pdfurl" width="100%" height="100%"></iframe>
    </div>
</el-dialog>
</template>

<script>
import axios from "axios";
import {
    ElMessageBox
} from "element-plus";
export default {
    data() {
        return {
            sfbankplace: "", //收款账户默认值 修改前的值

            pdfurl: "", //PDF文件urlf地址
            pdfurlVisible: false, //查看显示PDF文件
            //分类ID     0-项目图片  1-付款合同  2-付款申请   3-发票   
            //           4-银行回单  5-收款合同  6-收款凭证  7-资金计划附件
            fileclass: "6",
            prdclassid: "", //新增记录ID
            dialogVisible: false, //是否打开上传模态框
            issaveok: false, //如果增加成功，则新增按钮失效

            contract_list: [], //合同列表

            formdata: {
                text_no: "", //凭证编号
                in_date: "", //日期
                in_amount: "", //到账金额
                fk_company: "", //付款单位名称
                in_contract_id: "", //合同ID
                in_banks_id: "", //收款银行ID
                project_id: window.sessionStorage.getItem("project_ids"), //项目ID
                des_text: "", //付款说明
            },

            //附件列表数据
            tableData: {
                data: [],
            },
        };
    },
    created() {
        this.getSkcontractList();
    },
    methods: {

        //单击收款账户控件事件
        sfbanksselect(val) {
            console.log("单击付款账户子组件控件事件", val);
            this.formdata.in_banks_id = val.id;
        },

        //选择‘所属合同’，触发事件
        contract_select(val) {
            console.log("所属合同控件传来的数据", val);
            this.formdata.in_contract_id = val;
        },
        //判断录入的数据是否有空值
        isNullvalue() {
            if (this.formdata.text_no == "") {
                ElMessageBox.alert("凭证编号不能为空！", "提示", {
                    confirmButtonText: "取消",
                });
                return false
            } //凭证编号
            if (this.formdata.in_date == "") {
                ElMessageBox.alert("日期不能为空！", "提示", {
                    confirmButtonText: "取消",
                });
                return false
            } //日期
            if (this.formdata.in_amount == "") {
                ElMessageBox.alert("金额不能为空！", "提示", {
                    confirmButtonText: "取消",
                });
                return false
            } //到账金额
            if (this.formdata.fk_company == "") {
                ElMessageBox.alert("付款单位名称不能为空！", "提示", {
                    confirmButtonText: "取消",
                });
                return false
            } //付款单位名称
            if (this.formdata.in_contract_id == "") {
                ElMessageBox.alert("合同不能为空！", "提示", {
                    confirmButtonText: "取消",
                });
                return false
            } //合同ID
            if (this.formdata.in_banks_id == "") {
                ElMessageBox.alert("收款银行不能为空！", "提示", {
                    confirmButtonText: "取消",
                });
                return false
            } //收款银行ID
            if (this.formdata.project_id == "") {
                ElMessageBox.alert("project_id不能为空！", "提示", {
                    confirmButtonText: "取消",
                });
                return false
            }
            if (this.formdata.des_text == "取消") {
                ElMessageBox.alert("付款说明不能为空！", "提示", {
                    confirmButtonText: "",
                });
                return false
            } //付款说明
            return true
        },

        //新增收款登记
        addcontract() {
            //如果输值全部不为空，才可以新增收款
            if (this.isNullvalue()) {
                axios
                    .post("admin/IncomeRecord", this.formdata, {
                        headers: {
                            token: window.sessionStorage.getItem("token"),
                        },
                    })
                    // .then((response) => {
                    .then((response) => {
                        ElMessageBox.alert("新增成功！", "提示", {
                            confirmButtonText: "OK",
                        });
                        this.issaveok = true; //禁用新增按钮，打开上传按钮
                        this.prdclassid = response.data; //获取新增ID
                        console.log("新增ID", this.prdclassid);
                    })
                    //.catch((error) => {
                    .catch(() => {
                        ElMessageBox.alert("失败！", "提示", {
                            confirmButtonText: "OK",
                        });
                    })
                    .then(() => {
                        // 总是会执行
                    });
            }
        },
        //获取对应记录的附件
        getFileList() {
            axios
                .get("admin/AccessoryFile", {
                    params: {
                        fileclass_id: this.fileclass,
                        record_id: this.prdclassid,
                    },
                    headers: {
                        token: window.sessionStorage.getItem("token"),
                    },
                })
                .then((response) => {
                    console.log("******", response.data.data, "******");
                    this.tableData.data = response.data.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .then(() => {
                    // 总是会执行
                });
        },
        //获取收款合同列表
        getSkcontractList() {
            axios
                .get("admin/ScontractInfo", {
                    params: {
                        list_rows: 9999,
                        page: 1,
                        project_id: this.formdata.project_id,
                    },
                    headers: {
                        token: window.sessionStorage.getItem("token"),
                    },
                })
                .then((response) => {
                    console.log("******", response.data.data, "******");
                    this.contract_list = response.data.data.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .then(() => {
                    // 总是会执行
                });
        },
        //删除PDF文件 @click="deletePdfFile(scope.row.id)"
    deletePdfFile(rowid){
       axios
        .delete("admin/AccessoryFile/"+rowid, {
          params: {},
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("******11", response.data.data, "11******");
           this.getFileList()
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
        //查看PDf
        openPdfFile(url) {
            this.pdfurlVisible = true;
            this.pdfurl = url;
        },
    },
};
</script>

<style>
</style>
